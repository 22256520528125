<template>
<div>
    <div class="header text-center">
        <b-container>
            <div class="display-2 mb-3">
                Privacy Policy
            </div>
			<div class="lead m-0 text-muted">
                How we honor user privacy
            </div>
        </b-container>
    </div>
    <b-container class="document">
        <div class="mb-3 small text-muted">
            Published: 2/1/2021
        </div>
        <div class="text-muted mb-3">
            We are committed to respecting and protecting the privacy rights of our users, and we created this Privacy Policy to give you notice of how your individual information will (and will not) be used by us. This Privacy Policy provides
            information on our policies and procedures regarding the collection, use, storage and disclosure of such information we receive from (1) companies and individuals who use our Services and (2) visitors who simply browse the Services.
        </div>
        <div class="text-muted mb-3">
            By using or accessing any of the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, store, process and share your
            information in the following ways.
        </div>
        <div class="text-muted mb-3">
            This Privacy Policy may be updated from time to time. We will notify you of any material changes by posting the new Privacy Policy on this website. You are advised to consult this Privacy Policy regularly for any changes.
        </div>
        <div class="h4 mt-5 mb-4">
            1. What Information Do We Collect and How Do We Collect It?
        </div>
        <div class="text-muted mb-3">
            At various times, you could be asked to provide information such as your name, email address, or other information. Information collected is often defined as being either anonymous or personally identifiable:
        </div>
        <div class="text-muted mb-3">
            “Anonymous Information” refers to information that cannot be tied back to identify a specific individual. For instance, we may know that thousands of people have visited this Privacy Policy, but we do not necessarily know their names,
            where they live, or their date of birth. What we collect from visits such as this constitutes Anonymous Information. Some of the individuals who access our Services decline to use the personalization features that are available to them,
            and therefore those individuals are anonymous to us and the only data we collect about them is Anonymous Information.
        </div>
        <div class="text-muted mb-3">
            “Personally Identifiable Information” refers to information that tells us specifically who you are, such as your name or email address. Using the Services may allow us to "recognize" you to allow us to personalize the Services for you.
        </div>
        <div class="mb-3 font-weight-bold">
            Anonymous Information
        </div>
        <div class="text-muted mb-3">
            As is true of most apps and web sites, we gather certain information (such as mobile provider, operating system, etc.) automatically and store it in log files. We use this information, which does not identify individual users, to analyze
            trends, to administer the Services, to track users movements around the Services and to gather demographic information about our user base as a whole. We may link some of this automatically-collected data to certain Personally
            Identifiable Information.
        </div>
        <div class="mb-3 font-weight-bold">
            Personally Identifiable Information
        </div>
        <div class="text-muted mb-3">
            When you install the Services, we may ask you for some Personally Identifiable Information, such as your first and last name, email address, and other information.
        </div>
        <div class="text-muted mb-3">
            Some Personally Identifiable Information may also be provided to intermediaries and other Third Party Service Providers (defined in part (4) below) who assist us with the Services, but who may make no use of any such information other
            than to assist us in providing the Services. Except as otherwise provided in this Privacy Policy, however, we will not rent or sell your Personally Identifiable Information to third parties.
        </div>
        <div class="h4 mt-5 mb-4">
            2. How Do We Use Individual Information?
        </div>
        <div class="text-muted mb-3">
            We use individual information mainly to provide and improve the Services, to perform our contractual obligations in our Terms of Use, and we may also contact users regarding account activities, new version and product offerings, or other
            communications relevant to the Services. We do not sell or share any Personally Identifiable Information or other information about our users to any third parties except as specified in this Privacy Policy.
        </div>
        <div class="text-muted mb-3">
            If you contact us by email or by filling-out a registration form, we may keep a record of your contact information and correspondence, and may use your email address, and any information that you provide to us in your message, to respond
            to you.
        </div>
        <div class="text-muted mb-3">
            In addition, we may use the individual information described above to send you promotional communications regarding the Services. If you decide at any time that you no longer wish to receive such promotional communications from us, email
            us at aminoeditor@gmail.com and request to be removed from our list.
        </div>
        <div class="h4 mt-5 mb-4">
            3. How Do We Store Your Personal Information?
        </div>
        <div class="mb-3 font-weight-bold">
            Third Party Hosting, Serving and Storage
        </div>
        <div class="text-muted mb-3">
            We may contract with Third Party Service Providers from time to time, to provide for our hosting, authentication, serving, storage and telecommunication needs, etc., including without limitation storage of our users’ Personally
            Identifiable Information. We carefully choose such Third Party Service Providers with respect to their privacy and security practices, and unless otherwise stated in this Privacy Policy, these Third Party Service Providers do not have any
            right to use the Personally Identifiable Information we provide to them beyond what is necessary for them to assist us.
        </div>
        <div class="mb-3 font-weight-bold">
            Retention of Information
        </div>
        <div class="text-muted mb-3">
            How long we keep your Personally Identifiable Information depends on the reasons we collected it and how we use it (as described in parts (1) and (2) above), and we do need certain information in order to provide some of the Services to
            you. If you decide to delete your information, we may cancel your data record. We may retain an archived copy of certain records as we believe is necessary for our legal, accounting, and reasonable business purposes or is required by law.
        </div>
        <div class="h4 mt-5 mb-4">
            4. Do We Disseminate Any Personal Information?
        </div>
        <div class="mb-3 font-weight-bold">
            Service Providers
        </div>
        <div class="text-muted mb-3">
            We may use third parties to help operate and distribute our Services, authenticate users and deliver products and services, and may share limited Personally Identifiable Information with our service providers, vendors, suppliers, and
            other third parties that provide products or services for or through this Service (such as website or database hosting companies, authentication providers, credit card payment processors, address list hosting companies, e-mail service
            providers, analytics companies, distribution companies and other similar service providers that use such information on our behalf) (“Third Party Service Providers”). Unless otherwise stated, these Third Party Service Providers do not
            have any right to use the Personally Identifiable Information we provide to them beyond what is necessary for them to assist us.
        </div>
        <div class="mb-3 font-weight-bold">
            Aggregate Statistics
        </div>
        <div class="text-muted mb-3">
            We may disclose aggregate statistics regarding user behavior as a measure of interest in, and use of, our Services to third parties in the form of aggregate data, such as overall patterns or demographic reports that do not describe or
            identify any individual user or client. For example, aggregated data may be accessed by publishers running our serving technology and the advertisers they work with directly, and our advertisers may access aggregate geographic data for
            their campaigns.
        </div>
        <div class="mb-3 font-weight-bold">
            Complying with Legal Process
        </div>
        <div class="text-muted mb-3">
            We take reasonable steps to ensure the privacy of data uploaded. We reserve the right to use or disclose your Personally Identifiable Information and other information in response to subpoenas, court orders, warrants, or legal process, or
            to otherwise establish or exercise our legal rights or defend against legal claims or in the event you violate or breach an agreement with us. We may use and disclose your Personally Identifiable Information if we believe you will harm
            the property or rights of Company, its owners, or others.
        </div>
        <div class="mb-3 font-weight-bold">
            Business Transitions
        </div>
        <div class="text-muted mb-3">
            In the event the Company goes through a business transition, such as a merger, acquisition by another company, or sale of all or a portion of its assets as well as in the event of a bankruptcy, assignment for benefit of creditors or
            receivership, your Personally Identifiable Information may be disclosed and will likely be among the assets transferred. You may be notified thereafter via prominent notice on our web site for 30 days of any such change in ownership or
            control of your Personally Identifiable Information. We further reserve the right to disclose, transfer or sell your Personally Identifiable Information to companies who are affiliated with us in our sole discretion.
        </div>
        <div class="h4 mt-5 mb-4">
            5. Information About the Technology
        </div>
        <div class="text-muted mb-3">
            Some of the Services may employ cookies to collect non-Personally Identifiable Information in the course of providing clients with ad-serving services, for the purpose of helping clients determine which advertisement is suitable to be
            delivered to a user, or to maintain a user’s state (keeping them logged in, etc.). A cookie is a small text file that can be stored on a user's computer when that computer's browser views an advertisement or website of one of our clients.
            The cookie is associated with and unique to the specific computer. The cookie does not contain the user's name, address, phone number, email address, or anything that personally identifies it. We collect into the cookie only
            non-Personally Identifiable Information such as the type of the computer's operating system, type of internet browser software, what web pages were viewed and at what time, and geographic location of user's internet service provider.
        </div>
        <div class="h4 mt-5 mb-4">
            6. Contact Us
        </div>
        <div class="text-muted">
            If you have any questions or concerns about this Privacy Policy, please contact us by email at support@aminoeditor.com.
        </div>
    </b-container>
</div>
</template>

<script>
export default {
    name: 'Privacy'
}
</script>

<style lang="scss" scoped>
.header {
    padding: 5rem 0;
}
.document {
    a {
		color: inherit;
        text-decoration: underline;
    }
}
.container {
	max-width: 900px;
}
</style>
