<template>
<div>
    <div class="header text-center">
        <b-container>
            <div class="display-2">
                {{ status.description }}
            </div>
        </b-container>
    </div>
    <b-container class="document">
        <div class="mb-5">
            <div class="h3 mb-3">Components</div>
            <b-list-group>
                <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="component of components" :key="component.id">
                    {{ component.name }}
                    <b-badge :variant="variantByComponentStatus(component.status)" pill>{{ component.status }}</b-badge>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div class="mb-5">
            <div class="h3 mb-3">Incidents</div>
            <b-list-group v-if="incidents.length">
                <b-list-group-item active class="flex-column align-items-start" v-for="incident of incidents" :key="incident.id">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="h5 mb-1">{{ incident.name }}</div>
                        <small>{{incident.updated_at | moment("from", "now")}}</small>
                    </div>
                    <div class="mb-1">
                        {{ incident.incident_updates[0].body }}
                    </div>
                    <b-badge :variant="variantByIncidentStatus(incident.status)" pill>{{ incident.status }}</b-badge>
                </b-list-group-item>
            </b-list-group>
            <div class="text-muted" v-else>
                Amino is up and running. If you're experiencing an issue, please report it with as much detail as you can provide.
                <div class="mt-4 read-more">
					<a class="d-flex align-items-center" href="mailto:support@aminoeditor.com">
						support@aminoeditor.com
						<div class="arrow-button ml-3 d-flex align-items-center justify-content-center">
                            <span class="material-icons-outlined md-light">east</span>
                        </div>
					</a>
                </div>
            </div>
        </div>
        <hr class="my-5">
        <div class="mb-5">
            <div class="h3 mb-3">Scheduled Maintenance</div>
            <b-list-group v-if="scheduled_maintenances.length">
                <b-list-group-item class="flex-column align-items-start" v-for="scheduled_maintenance of scheduled_maintenances" :key="scheduled_maintenance.id">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="h5 mb-1">{{ scheduled_maintenance.name }}</div>
                        <div class="small" v-if="scheduled_maintenance.status == 'scheduled'">Starts {{ scheduled_maintenance.scheduled_for | moment("from", "now") }}</div>
                        <div class="small" v-else-if="scheduled_maintenance.status == 'in_progress'">Ends {{ scheduled_maintenance.scheduled_until | moment("from", "now") }}</div>
                    </div>
                    <div class="mb-1">
                        {{ scheduled_maintenance.incident_updates[0].body }}
                    </div>
                    <b-badge :variant="dark" pill>{{ scheduled_maintenance.status }}</b-badge>
                </b-list-group-item>
            </b-list-group>
            <div class="text-muted" v-else>
                No maintenance events planned.
            </div>
        </div>
		<ModuleFollow />
    </b-container>

</div>
</template>

<script>
const ModuleFollow = () => import('@/components/ModuleFollow');
export default {
    name: 'Permissions',
    components: {
		ModuleFollow
	},
    data() {
        return {
            components: [],
            incidents: [],
            page: {},
            scheduled_maintenances: [],
            status: {},
            statusInterval: false
        }
    },
    methods: {
        variantByComponentStatus(status) {
            let variant = 'success';
            switch (status) {
                case 'degraded_performance':
                case 'partial_outage':
                    variant = 'warning';
                    break;
                case 'major_outage':
                    variant = 'danger';
                    break;
                case 'under_maintenance':
                    variant = 'primary';
                    break;
            }
            return variant;
        },
        variantByIncidentStatus(status) {
            let variant = 'success';
            switch (status) {
                case 'investigating':
                    variant = 'danger';
                    break;
                case 'identified':
                    variant = 'warning';
                    break;
            }
            return variant;
        },
        variantByMaintenanceStatus(status) {
            let variant = 'primary';
            switch (status) {
                case 'scheduled':
                    variant = 'warning';
                    break;
            }
            return variant;
        },
        loadStatus() {
            fetch('https://q9g778g71y2k.statuspage.io/api/v2/summary.json').then(res => {
                return res.json();
            }).then(json => {
                for (let key in json) {
                    this[key] = json[key];
                }
            });
        }
    },
    mounted() {
        this.loadStatus();
        this.statusInterval = setInterval(this.loadStatus, 30000);
    },
    unmounted() {
        clearInterval(this.statusInterval);
    }
}
</script>

<style lang="scss" scoped>
.header {
    padding: 5rem 0;
}
.container {
    max-width: 900px;
}
.read-more {
    a {
		text-decoration: none;
        color: var(--color-100);
        &:hover {
            color: var(--color-white);
        }
    }
}
</style>
