<template>
<div>
	<div class="header text-center">
        <b-container>
            <div class="display-2 mb-3">
                A Note About Permissions
            </div>
			<div class="lead m-0 text-muted">
                TLDR: It's nothing unusual or creepy
            </div>
        </b-container>
    </div>
    <b-container class="document">
		<div class="text-muted mb-3">
			Occasionally we hear from users who want to know more about the permissions that are requested during installation of our browser extension. Here’s a brief explanation of the two permissions we request and why we need them.
		</div>
		<div class="h3 mt-5 mb-4">
			"Read and change all your data on the websites you visit"
		</div>
		<div class="text-muted mb-3">
			The “read and change” popup is standard across browser extensions and Amino does not capture or alter any personal or payment information from the websites you visit. The only website ‘change’ that is incurred while using Amino is
			that we inject your user style sheet into the page you’re styling. This is the primary function of our extension and could not function without this permission.
		</div>
		<div class="h3 mt-5 mb-4">
			"Display notifications"
		</div>
		<div class="text-muted mb-3">
			When you right click on an element and select “Copy This Selector” from the Amino context menu, we use a browser notification to inform you that the selector has been added to your clipboard. We felt that this was the cleanest and
			most intuitive way to notify you of this.
		</div>
		<div class="text-muted mb-3">
			Please read our <router-link to="/privacy">Privacy Policy</router-link> for further information on how we honor user privacy.
		</div>
    </b-container>
</div>
</template>

<script>
export default {
    name: 'Permissions'
}
</script>

<style lang="scss" scoped>
.header {
    padding: 5rem 0;
}
.document {
    a {
		color: inherit;
        text-decoration: underline;
    }
}
.container {
	max-width: 900px;
}
</style>
