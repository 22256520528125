<template>
<div>
    <div class="header text-center">
        <b-container>
            <div class="display-2 mb-3">
                Terms of Service
            </div>
			<div class="lead m-0 text-muted">
                Please read this agreement carefully before accessing or using Amino
            </div>
        </b-container>
    </div>
    <b-container class="document">
		<div class="h4 mb-4">
			Introduction
		</div>
		<div class="text-muted mb-3">
			These Enterprise Terms of Service ("Terms", "Terms of Service") are entered into by and between you and Amino Ventures LLC dba Amino ("Amino"). Please read them carefully before using the aminoeditor.com website or browser
			extensions (the "Service").
		</div>
		<div class="text-muted mb-3">
			Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
		</div>
		<div class="text-muted mb-3">
			By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
		</div>
		<div class="text-muted mb-3">
			We respect the privacy and security of our users. You understand that by using the Service, you give consent to the collection, use and disclosure of our personally identifiable information as well as any non-personally
			identifiable information, as described in more detail in our <router-link to="/privacy">Privacy Policy</router-link> which is incorporated herein by reference.
		</div>
		<div class="h4 mt-5 mb-4">
			1. Links To Other Web Sites
		</div>
		<div class="text-muted mb-3">
			Our Service may contain links to third-party web sites or services that are not owned or controlled by the Service.
		</div>
		<div class="text-muted mb-3">
			The Service has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Service shall not be responsible
			or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
		</div>
		<div class="text-muted mb-3">
			We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
		</div>
		<div class="h4 mt-5 mb-4">
			2. Governing Law
		</div>
		<div class="text-muted mb-3">
			These Terms shall be governed and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions.
		</div>
		<div class="text-muted mb-3">
			Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these
			Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
		</div>
		<div class="h4 mt-5 mb-4">
			3. Changes
		</div>
		<div class="text-muted mb-3">
			We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material
			change will be determined at our sole discretion.
		</div>
		<div class="text-muted mb-3">
			By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
		</div>
		<div class="text-muted mb-3">
			These Terms of Service, together with the <router-link to="/privacy">Privacy Policy</router-link> and any other legal notices published by us on the Service, shall constitute the entire agreement between you and us concerning this
			Service and supersedes any prior written or oral representations.
		</div>
		<div class="h4 mt-5 mb-4">
			4. Contact Us
		</div>
		<div class="text-muted">
			If you have any questions or concerns about these Terms of Use, please contact us by email at support@aminoeditor.com.
		</div>
    </b-container>
</div>
</template>

<script>
export default {
    name: 'Terms'
}
</script>

<style lang="scss" scoped>
.header {
    padding: 5rem 0;
}
.document {
    a {
		color: inherit;
        text-decoration: underline;
    }
}
.container {
	max-width: 900px;
}
</style>
